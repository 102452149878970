.container {
    text-align: center;
    color: #ffffff;
    .efficiency {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        .value {
            font-size: 52px;
            margin-right: 16px;
            font-weight: 600;
            min-width: 64px;
            min-height: 60px;
            position: relative;
            .item {
                margin-left: 8px;
                text-align: left;
                position: absolute;
                bottom: 0;
                height: 90%;
                display: inline;
                .tag {
                    background-color: #00c474;
                    font-size: 11px;
                    border-radius: 7px 7px 7px 0px;
                    padding: 2px 8px;
                    margin-bottom: 12px;
                    white-space: nowrap;
                }
                .unit {
                    font-size: 10px;
                }
            }
        }
    }

    .state {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 24px 0 33px 0;
        .item {
            display: flex;
            align-items: center;
            .icon {
                width: 14px;
                height: 14px;
            }
            .label {
                margin-left: 8px;
                font-size: 15px;
            }
        }
    }
}
