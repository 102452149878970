@import "../../app.css";
@media screen and (max-width: 365px) {
    .contract {
        font-size: 13px !important;
    }
    .user {
        .status {
            font-size: 13px !important;
        }
    }
    .panel {
        span {
            font-size: 13px !important;
        }
        .title {
            font-size: 19px !important;
        }
    }
    .unit {
        .id-panel {
            font-size: 13px !important;
        }
        .detail-item {
            font-size: 13px !important;
        }
    }
    button {
        font-size: 13px !important;
    }
    .steps {
        .step-item {
            .title {
                font-size: 12px !important;
            }
            .date {
                padding-left: 0px !important;
                font-size: 12px;
            }
            span {
                font-size: 12px;
            }
        }
    }
    .fix {
        .result {
            font-size: 13px !important;
        }
    }
}

.fix {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 16px;
    min-height: 100vh;
    min-height: calc(100vh - 32px);
    // background: url(../../assets/ocl-callback.png) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;

    .panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 15px;
        font-weight: 300;
        line-height: 21px;
        color: #ffffff;

        padding: 12px;

        :global(.icon) {
            margin: 20px;
        }

        span {
            margin-bottom: 4px;
        }

        .title {
            font-size: 21px;
            font-weight: 600;
            line-height: 29px;
            color: #ffffff;
            margin-bottom: 16px;
        }
    }

    .card {
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 10px;
        .contract {
            background-color: var(--adm-color-primary);
            color: #ffffff;
            font-size: 15px;
            text-align: center;
            height: 38px;
            line-height: 38px;
            border-radius: 4px;
        }
    }

    .user {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 16px;

        .avatar {
            width: 49px;
            height: 49px;
            background: #f1f1f1;
            border-radius: 50%;
            background: url("../../assets/avatar.svg");
            margin-right: 16px;
            min-width: 49px;
        }

        .info {
            flex: 1;

            .basic {
                margin-bottom: 4px;
                display: flex;
                align-items: center;

                .name {
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 24px;
                    margin-right: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 0;
                    flex: 1;
                }

                .health-icon {
                    width: 15px;
                    height: 15px;
                    margin-right: 2px;
                }

                .health {
                    font-size: 11px;
                    font-weight: 300;
                    line-height: 16px;
                    color: #00c474;
                    width: 0;
                    flex: 1;
                }
            }

            .status {
                font-size: 15px;
                font-weight: 300;
                line-height: 21px;
            }
        }

        .call {
            width: 28px;
            height: 28px;
            margin: 8px;
        }
    }

    .result {
        background: #f6f6f6;
        // height: 54px;
        padding: 8px 16px;
        font-size: 15px;
        font-weight: 300;
        line-height: 21px;
        display: flex;
        align-items: center;
        border-radius: 4px;
    }

    .main-icon {
        color: var(--adm-color-primary);
        width: 56px;
        height: 50px;
    }
    .arrow {
        width: 12px;
        height: 12px;
        border-top: 1px solid #cccccc;
        border-left: 1px solid #cccccc;
        transform: rotate(315deg);
        flex: none !important;
    }
    .line1 {
        width: 1px;
        height: 25px;
        border-left: 1px solid #cccccc;
        position: relative;
        left: 8px;
        bottom: 21px;
        flex: none !important;
    }
    .line2 {
        width: 1px;
        height: 28px;
        border-left: 1px solid #cccccc;
        position: relative;
        right: 7px;
        top: 22px;
        flex: none !important;
    }

    .steps {
        // padding: 12px 0 0px 12px;
        background-color: #ffffff;
        border-radius: 6px;
        .steps-icon {
            width: 12px;
            height: 12px;
        }

        .step-item {
            display: flex;
            justify-content: space-between;
            margin: 0 8px;
            span {
                display: inline-block;
            }
            .title {
                width: 0;
                flex: 1;
                font-size: 14px;
                text-align: left;
            }
            .disabled {
                color: #cccccc;
            }
            .date {
                padding-left: 20px;
                flex: 1;
            }
        }

        :global {
            .adm-steps {
                width: inherit;
                padding-bottom: 4px;
            }
            .adm-steps-vertical .adm-step .adm-step-status-wait .adm-step-content {
                color: pink;
            }

            .adm-step-status-process .adm-step-title {
                color: #54555a;
            }
            .adm-steps-vertical .adm-step .adm-step-content {
                padding-bottom: 21px;
                padding-top: 3px;
            }

            .adm-steps-vertical .adm-step .adm-step-content .adm-step-title {
                font-size: 15px;
                font-weight: 400;
                line-height: 21px;
            }

            .adm-steps-vertical .adm-step .adm-step-indicator:after {
                background-color: transparent;
                background-image: linear-gradient(var(--line-to-next-color) 33%, rgba(255, 255, 255, 0) 0%);
                background-position: right;
                background-size: 1px 6px;
                background-repeat: repeat-y;
            }

            .adm-steps-vertical .adm-step .adm-step-indicator {
                // margin-right: 22px;
            }
        }
    }

    .unit {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 16px;

        height: 99px;

        > div {
            flex: 1;
        }

        .id-panel {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            font-size: 15px;
            font-weight: 300;
            line-height: 21px;

            .id {
                font-size: 17px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 4px;
            }
        }

        .detail {
            padding: 0 14px;
            // border-left: 1px solid #f1f1f1;

            .detail-item {
                margin-bottom: 12px;
                font-size: 15px;
                font-weight: 600;
                line-height: 21px;

                .label {
                    display: inline-block;
                    font-weight: 300;
                    margin-right: 8px;
                    color: #000000;
                    .required {
                        color: var(--adm-color-primary);
                    }
                }
            }

            .detail-item:last-child {
                margin-bottom: 0;
            }
        }
    }
    .des {
        color: #ffffff;
        font-size: 10px;
    }

    button {
        margin: 10px 0;
        height: 40px;
        background: rgba(246, 82, 117, 0.16);
        color: var(--adm-color-primary);
        font-size: 17px;
        line-height: 24px;
        border: 1px solid var(--adm-color-primary);
        opacity: 1;
        border-radius: 4px;
    }
}
