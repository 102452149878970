.container {
    padding: 60px;
    text-align: center;
    color: #ffffff;
    min-height: 100vh;
    min-height: calc(100vh - 32px);
    background-repeat: no-repeat;
    background-size: cover;
    .title {
        font-size: 50px;
    }
    .desc {
        font-size: 17px;
        margin-top: 74px;
    }
    .iconClass{
      width: 164px;
      height: 108px;
  }
}
