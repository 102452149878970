.viewpager {
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: black;
    color: #eeeeee;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    .title {
        font-size: 38px;
        padding-top: 10%;
    }
    .btn_copy {
        width: 310px;
        margin-top: 60px;
    }
    .bg1 {
        background: url(../../assets/bg1.png) no-repeat;
        background-size: cover;
        margin: 32px auto 16px auto;
        width: 240px;
        height: 140px;
    }
    .bg2 {
        background: url(../../assets/bg2.png) no-repeat;
        background-size: cover;
        margin: 16px auto;
        width: 57px;
        height: 87px;
    }
    .oebg {
        background: url(../../assets/oebg.png) no-repeat;
        background-size: cover;
        margin: 32px auto 16px auto;
        width: 240px;
        height: 140px;
    }
    p {
        font-size: 17px;
        font-weight: 400;
        color: #ffffff;
    }
}
