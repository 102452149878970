@media screen and (max-width: 768px) {
    .confirmBtn {
        width: 100%;
        height: auto;
        white-space: normal;
    }
    .privacyModal {
        :global {
            .ant-modal-title {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
    .wrapper {
        :global {
            .ant-popover {
                left: 480px !important;
            }
        }
    }
}
//iphone5/SE
@media screen and (max-width: 690px) {
    .wrapper {
        :global {
            .ant-popover {
                left: 390px !important;
            }
        }
    }
}
//iphone5/SE
@media screen and (max-width: 610px) {
    .wrapper {
        :global {
            .ant-popover {
                left: 350px !important;
            }
        }
    }
}
//iphone5/SE
@media screen and (max-width: 570px) {
    .wrapper {
        :global {
            .ant-popover {
                left: 280px !important;
            }
        }
    }
}
//iphone5/SE
@media screen and (max-width: 320px) {
    .wrapper {
        :global {
            .ant-popover {
                left: 80px !important;
            }
        }
    }
}

//iphone6/7/8
@media screen and (max-width: 375px) {
    .wrapper {
        :global {
            .ant-popover {
                left: 118px !important;
            }
        }
    }
}
//iphone6/7/8 PLUS
@media screen and (max-width: 478px) {
    .wrapper {
        :global {
            .ant-popover {
                left: 136px !important;
            }
        }
    }
}

.checkbox {
    :global {
        .ant-checkbox-wrapper {
            padding: 0 !important;
        }
    }
}

.modal {
    text-align: center !important;

    :global {
        img {
            margin-bottom: 40px;
        }

        .contact {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        button {
            width: 280px;
            border: none;
        }

        button:hover {
            border: none;
        }
    }
}

.success,
.warning,
.error {
    transition: color 0.3s;
}

.success,
.error {
    margin: 10px 0 0;
    white-space: nowrap;

    &:first-child {
        margin-top: 0;
    }

    :global {
        i {
            display: inline-block;
            margin: 4px 4px 0 0;
            vertical-align: top;
            transform: scale(0.8);
        }

        span {
            display: inline-block;
            margin-right: 16px;
            white-space: initial;
        }
    }
}

.main {
    width: 348px;
    margin: 0 auto;
    padding: 4vh 0;
    .top {
      text-align: center;
    }
    
    .header {
      height: 44px;
      line-height: 44px;
    
      a {
        text-decoration: none;
      }
    }
    
    .logo {
      height: 44px;
      margin-right: 16px;
      vertical-align: top;
    }
    
    .title {
      position: relative;
      top: 2px;
      font-weight: 600;
      font-size: 33px;
      font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
    }
    .desc {
        margin-top: 4px;
        margin-bottom: 30px;
        color: #a7a8a9;
        font-size: 14px;
        text-align: center;
    }
    //   @media screen and (max-width: @screen-sm) {
    //     width: 95%;
    //     max-width: 328px;
    //   }

    :global {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        .ant-tabs-bottom > .ant-tabs-nav::before,
        .ant-tabs-bottom > div > .ant-tabs-nav::before,
        .ant-tabs-top > .ant-tabs-nav::before,
        .ant-tabs-top > div > .ant-tabs-nav::before {
            border-bottom: 0;
        }
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                margin-right: 80px;
                padding-bottom: 6px;
                &:nth-child(2) {
                    margin-right: 0;
                }
            }
        }
        // .@{ant-prefix}-tabs-nav-list {
        //   margin: auto;
        //   font-size: 16px;
        // }
        .ant-layout-footer {
            padding: 24px 0px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            background: #f0f2f5;
        }
        .ant-tabs-nav {
            margin-bottom: 3vh;
        }
    }

    .icon {
        margin-left: 16px;
        color: rgba(0, 0, 0, 0.2);
        font-size: 24px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        // &:hover {
        //   color: @primary-color;
        // }
    }

    .other {
        margin-top: 24px;
        line-height: 22px;
        text-align: left;

        .register {
            float: right;
        }
    }

    //   .prefixIcon {
    //     color: @primary-color;
    //     font-size: @font-size-base;
    //   }
}

.linkbtn {
    padding: 0;
}

.loginsuccess {
    button {
        width: 46%;
        height: 40px;
        margin: 0 auto;
    }
    .btns {
        margin-top: 8vh;
        button {
            display: block;
            width: 100%;
        }
    }

    .ant-result-title {
        color: #000;
        font-size: 20px;
    }

    .ant-result-subtitle {
        padding: 8px 0 20px;
        color: #54555a;
        font-size: 14px;
    }
    .ant-result {
        padding: 20px;
    }
    .ant-result-extra {
        display: flex;
        justify-content: space-between;
    }
}

.wrapper {
    :global {
        .ant-form-item-explain.ant-form-item-explain-error {
            color: #ff4d4f;
        }
        .ant-form-item-has-error .ant-input,
        .ant-form-item-has-error .ant-input-affix-wrapper,
        .ant-form-item-has-error .ant-input:hover,
        .ant-form-item-has-error .ant-input-affix-wrapper:hover {
            background-color: #fff;
            border-color: #ff4d4f;
        }
        .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
            display: none;
        }
        .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
            margin-right: -32px;
        }
        .ant-result-title {
            font-size: 16px;
        }
    }
}

.title {
    margin-bottom: 40px;
    color: #54555a;
    text-align: center;
}

.label {
    margin-bottom: 20px;
    color: #54555a;
}

.successwrapper {
    margin: 40px 0;

    :global {
        .ant-result {
            padding: 0 0 46px;
        }

        .ant-result-title {
            font-size: 16px;
        }
    }
}
:global {
    .btnCaptha {
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            background: #fff;
        }
    }
    #UserRegister {
        .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
            padding-right: 0;
        }
    }
}
