.container {
    padding: 16px;
    min-height: 100vh;
    min-height: calc(100vh - 32px);
    background-repeat: no-repeat;
    background-size: cover;
    .card {
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 10px;
        .contract {
            background-color: var(--adm-color-primary);
            color: #ffffff;
            font-size: 15px;
            text-align: center;
            height: 38px;
            line-height: 38px;
            border-radius: 4px;
        }
    }

    .user {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 16px;

        .avatar {
            width: 49px;
            height: 49px;
            background: #f1f1f1;
            border-radius: 50%;
            background: url("../../assets/avatar.svg");
            margin-right: 16px;
            min-width: 49px;
        }

        .info2 {
            flex: 1;

            .basic {
                margin-bottom: 4px;
                display: flex;
                align-items: center;

                .name {
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 24px;
                    margin-right: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 0;
                    flex: 1;
                }

                .health-icon {
                    width: 15px;
                    height: 15px;
                    margin-right: 2px;
                }

                .health {
                    font-size: 11px;
                    font-weight: 300;
                    line-height: 16px;
                    color: #00c474;
                    width: 0;
                    flex: 1;
                }
            }

            .status {
                font-size: 15px;
                font-weight: 300;
                line-height: 21px;
            }
        }

        .call {
            width: 28px;
            height: 28px;
            margin: 8px;
        }
    }
    .mt16 {
        margin-top: 16px;
    }
    .item {
        margin-top: 12px;
        .icon {
            width: 30px;
            height: 30px;
        }
        p {
            font-size: 11px;
            font-weight: 400;
        }
        p:last-child {
            font-size: 11px;
            font-weight: 300;
        }
    }
    .item_disabled {
        margin-top: 12px;
        overflow: hidden;
        color: #8e8e8e;
        .icon {
            width: 30px;
            height: 30px;
            filter: drop-shadow(#8e8e8e 1000px 0);
            transform: translateX(-1000px);
        }
        p {
            font-size: 11px;
            font-weight: 400;
        }
        p:last-child {
            font-size: 11px;
            font-weight: 300;
        }
    }
    .info {
        padding: 16px;
        background-color: #ffffff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        text-align: center;
        justify-content: space-around;
        .item {
            .required {
                color: var(--adm-color-primary);
                font-style: 11px;
                margin-left: 4px;
            }
            span {
                color: #8e8e8e;
                font-style: 11px;
            }
            span:last-child {
                color: #000000;
                font-weight: 600;
                margin-top: 4px;
                display: block;
            }
        }
    }
    .maintain {
        background-color: #cba052;
        color: #ffffff;
        text-align: center;
        padding: 10px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .unmaintained {
        font-size: 11px;
        background-color: #f1f1f1;
        color: #8e8e8e;
        text-align: center;
        padding: 10px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .service {
        margin-top: 16px;
        background-color: #003e80;
        padding: 16px;
        text-align: center;
        color: #ffffff;
        .header {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #ffffff;
            padding-bottom: 8px;
            .icon {
                width: 21px;
                height: 21px;
                margin-right: 8px;
            }
        }
    }
}
:global {
    .adm-card {
        border-radius: 4px;
    }
    .adm-card-header {
        padding-bottom: 0;
    }
    .adm-card-header:not(:last-child) {
        border: none;
    }
    .adm-card-body {
        padding: 0;
    }
}
