.hidden {
    display: none;
}
.container {
    padding: 16px;
    min-height: 100vh;
    // min-height: calc(100vh - 32px);
    background-repeat: no-repeat;
    background-size: cover;

    .h1 {
        font-size: 21px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        margin: 61px 0 16px 0;

        font-family: PingFang SC-Light, PingFang SC;
    }
    .h2 {
        font-size: 15px;
        font-weight: 300;
        color: #ffffff;
        text-align: center;
        margin: 16px 0;

        font-family: PingFang SC-Semibold, PingFang SC;
    }
    .footer {
        color: #ffffff;
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }
}
