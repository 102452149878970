.container {
    padding: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #ffffff;
    min-height: 100vh;
    min-height: calc(100vh - 32px);
    .title {
        font-size: 14px;
        font-weight: 500;
        margin: 10px 0 42px 0;
    }
    .icon {
        width: 54px;
        height: 54px;
    }
    .desc {
        font-size: 21px;
        font-weight: 600;
        margin: 16px 0;
    }
    .card{
      border-radius: 4px;
      background-color: #ffffff;
      padding: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-align: left;
      .tel{
        color: var(--adm-color-primary);
        margin-top: 8px;
        text-decoration:none
      }
    }
}
