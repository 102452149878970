.mode {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 160px;
    box-sizing: border-box;
}

.text {
    font-size: 17px;
    color: #FFFFFF;
    margin-top: 80px;
}

.icon {
    width: 75px;
    height: 80px;
}