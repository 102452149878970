.maintain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 16px;
    min-height: 100vh;
    min-height: calc(100vh - 32px);
    // background: url(../../assets/ocl-callback.png) no-repeat;
    background-size: cover;
    .panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        .icon {
            margin: 32px auto 16px auto;
            width: 46px;
            height: 59px;
        }
        .title {
            font-size: 21px;
            font-weight: 600;
        }
        .date {
            margin: 16px 0;
        }
    }
    .card {
        width: 100%;
        height: 247px;
        background: #ffffff;
        border-radius: 4px;
        .date {
            width: 85px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #F1F1F1;
            border-radius: 0px 4px 4px 0px;
            position: relative;
            right: 16px;
            top: 8px;
            color: #CBA052;
            font-size: 17px;
            font-weight: 700;
        }
    }
}
